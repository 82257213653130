import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { UseFormRegister, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormGetValues } from 'react-hook-form'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { formatMoney } from '../../../util/number'
import { HelpTextProps } from '../../HelpText'
import { CustomMessageType } from '../FormBuilder'
import { FormElementErrorMessage, FormElementLabel } from './Input'

export type TextDescriptiveInputProps = {
  label?: string
  property?: string
  placeholder?: string
  required?: boolean
  showOptionalIfNotRequired?: boolean
  register?: UseFormRegister<any>
  setValue?: UseFormSetValue<any>
  getValues?: UseFormGetValues<any>
  trigger?: UseFormTrigger<any>
  validators?: any
  type?: string
  inputType?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal'
  accept?: string
  errors?: FieldErrors
  minValue?: string | number
  maxValue?: string | number
  autocomplete?: string
  readonly?: boolean
  disabled?: boolean
  minLength?: number
  maxLength?: number
  pattern?: RegExp
  value?: any
  prefix?: string
  postfix?: string
  customMessage?: string
  customMessageType?: CustomMessageType
  customPatternErrorMessage?: string
  onChange?: (args?: any) => void
  selectOnClick?: boolean
  additionalActionText?: string
  additionalActionFn?: (...args) => any
  helpText?: HelpTextProps
  additionalClassNames?: string
}

export const TextDescriptiveInput = ({
  label = '',
  required = false,
  showOptionalIfNotRequired = true,
  property = undefined,
  placeholder = undefined,
  register = (...args) => null,
  onChange = (...args) => null,
  validators = {} as any,
  type = undefined,
  inputType = 'text',
  accept = undefined,
  errors = undefined,
  autocomplete = undefined,
  readonly = false,
  disabled = false,
  minValue = undefined,
  maxValue = undefined,
  minLength = undefined,
  maxLength = undefined,
  pattern = undefined,
  value = undefined,
  prefix = undefined,
  postfix = undefined,
  customMessage = undefined,
  customMessageType = CustomMessageType.Error,
  customPatternErrorMessage = undefined,
  selectOnClick = false,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
  additionalClassNames = '',
}: TextDescriptiveInputProps) => {
  const currency = useUserCurrency()

  const [currentValue, setCurrentValue] = useState(value)
  const [passwordHidden, setPasswordHidden] = useState(true)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const _onClick = (ev) => {
    if (selectOnClick) ev?.target?.select()
  }

  const _onChange = (ev) => {
    setCurrentValue(ev?.target?.value)
    onChange(ev)
  }

  return (
    <>
      <div className="col-span-2 hidden"></div>
      <div className={`descriptive-input flex flex-col mb-4 ${additionalClassNames}`}>
        <div className="descriptive-input-wrapper divide-y divide-solid divide-gray-500 divide-opacity-60">
          <div
            className={`descriptive-input-inner flex space-x-2 pt-2 pb-1 items-center text-formContrast ${
              !disabled && !readonly ? 'focus:border-primary' : ''
            } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${
              readonly || disabled ? ' opacity-60' : ''
            }`}
          >
            {prefix && (
              <div className="descriptive-input-prefix text-sm font-semibold text-gray-500">
                <span>{prefix}</span>
              </div>
            )}

            <input
              className={`descriptive-input-input flex w-full break-all text-sm text-center bg-transparent text-backgroundContrast focus:outline-none appearance-none placeholder:opacity-60 placeholder:text-backgroundContrast`}
              name={property}
              id={property}
              placeholder={placeholder}
              onClick={_onClick}
              onChange={_onChange}
              type={passwordHidden ? type : 'text'}
              inputMode={inputType}
              accept={accept}
              min={minValue}
              max={maxValue}
              step="any"
              autoComplete={autocomplete}
              readOnly={readonly}
              disabled={disabled}
              maxLength={maxLength}
              {...register(property, {
                required: required,
                min: minValue,
                max: maxValue,
                minLength: minLength,
                maxLength: maxLength,
                pattern: pattern,
                validate: validators,
              })}
              value={currentValue}
            />

            {(postfix || type === 'password') && (
              <div className="descriptive-input-postfix text-sm font-semibold text-gray-500">
                {postfix != null && type != 'password' && <span>{postfix}</span>}
                {type === 'password' && (
                  <div className="p-1" onClick={() => setPasswordHidden(!passwordHidden)}>
                    {passwordHidden ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                  </div>
                )}
              </div>
            )}
          </div>

          <FormElementLabel
            label={label}
            property={property}
            required={required}
            showOptionalIfNotRequired={showOptionalIfNotRequired}
            readonly={readonly}
            helpText={helpText}
          />
        </div>

        {additionalActionText && (
          <div
            className="descriptive-input-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
            onClick={additionalActionFn}
          >
            {additionalActionText}
          </div>
        )}

        <FormElementErrorMessage
          customMessage={customMessage}
          customMessageType={customMessageType}
          customPatternErrorMessage={customPatternErrorMessage}
          errors={errors}
          minLength={minLength}
          maxLength={maxLength}
          minValue={formatMoney(Number(minValue), currency)}
          maxValue={formatMoney(Number(maxValue), currency)}
        />
      </div>
    </>
  )
}
