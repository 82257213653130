import { SelectOption } from '../../util/select-option'
import HelpText from '../HelpText'

export type RadioGroupProps = {
  options: SelectOption[]
  selectedValue: any
  onChange: (value: any) => void
  label?: string
  infoText?: string
  addBottomMargin?: boolean
}

export const RadioGroup = ({
  options = [],
  selectedValue,
  onChange,
  label = '',
  infoText = '',
  addBottomMargin = true,
}: RadioGroupProps) => {
  return (
    <div className={`radio-group flex flex-col space-y-1 ${addBottomMargin ? 'mb-4' : ''}`}>
      {(label || infoText) && (
        <div className="radio-group-info flex space-x-1 items-center ">
          {label && (
            <span className="radio-group-info-label flex text-backgroundContrast text-xs opacity-60">{label}</span>
          )}
          {infoText && <HelpText title={label} description={infoText} />}
        </div>
      )}
      <div className="radio-group-options flex flex-row items-stretch space-x-2">
        {options.map((opt, index) => {
          return (
            <div
              className={`radio-group-option flex space-x-4 items-center px-4 py-2 border-[.5px] transition-all rounded-lg cursor-pointer ${
                opt.value === selectedValue
                  ? 'bg-primary border-primary text-primaryContrast'
                  : 'border-formBorder bg-form text-formContrast hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder'
              }`}
              key={index}
              onClick={() => onChange(opt.value)}
            >
              <span className="radio-group-option-name text-sm">{opt.name}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
