import { FormElementErrorMessage, FormElementLabel, TextInputProps } from './Input'
import { CustomMessageType } from '../FormBuilder'
import React, { useEffect, useState } from 'react'
import { SelectOption } from '../../../util/select-option'
import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Country } from '@arland-bmnext/api-data'

type InputPhoneNumberProps = TextInputProps & {
  countries?: Country[]
}

export const InputPhoneNumber = ({
  label = '',
  property = undefined,
  required = false,
  showOptionalIfNotRequired = true,
  readonly = false,
  placeholder = undefined,
  register = (...args) => null,
  setValue = (...args) => null,
  getValues = (...args) => null,
  onChange = (...args) => null,
  validators = {} as any,
  minLength = undefined,
  maxLength = undefined,
  errors = undefined,
  disabled = false,
  value = '',
  customMessage = undefined,
  customMessageType = CustomMessageType.Error,
  selectOnClick = false,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
  fixedCountryCallingCode = null,
  countries = [],
  pattern = undefined,
}: InputPhoneNumberProps) => {
  const [countryCodes, setCountryCodes] = useState([])

  const [currentCountryCodeValue, setCurrentCountryCodeValue] = useState('')
  const [currentPhoneNumberValue, setCurrentPhoneNumberValue] = useState('')

  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    if (countries != null && countries.length > 0 && countryCodes.length === 0) {
      const cc: PhoneNumberPrefixSelectOption[] = []
      countries?.forEach((country) => {
        cc.push({
          value: country.callingCode.toString(),
          name: '+' + country.callingCode,
          countryName: country.name,
          countryId: country.id,
        })
      })
      if (fixedCountryCallingCode != null) {
        const countryCode = cc?.find((countryCode) => countryCode.name == fixedCountryCallingCode)
        if (countryCode != undefined) {
          setCountryCodes([countryCode])
          return
        }
      }
      const sorted = cc.sort((a, b) => a.countryName.toString().localeCompare(b.countryName.toString()))
      setCountryCodes(sorted)
      return
    }
  }, [countries])

  useEffect(() => {
    if (countryCodes != null && countryCodes.length > 0) {
      parseInputValue()
    }
  }, [countryCodes])

  const parseInputValue = () => {
    const formValue = getValues != null ? getValues(property) : null

    let _value = null
    if (value != null && value != '') _value = value
    if (formValue != null && formValue != '') _value = formValue

    if (_value) {
      const parse: PhoneNumber = parsePhoneNumber(_value)
      if (parse) {
        setCurrentCountryCodeValue(parse.countryCallingCode)
        setCurrentPhoneNumberValue(parse.nationalNumber)
      } else {
        const foundCountryCode = countryCodes.find((x) => x.name == _value || x.value == _value)
        if (foundCountryCode != null) {
          setCurrentCountryCodeValue(foundCountryCode.value)
        }
      }
    }
  }

  useEffect(() => {
    if (currentCountryCodeValue && currentPhoneNumberValue)
      updateTotalValue(currentCountryCodeValue, currentPhoneNumberValue)
  }, [currentCountryCodeValue, currentPhoneNumberValue])

  const onPhoneNumberClick = (ev) => {
    if (selectOnClick) ev?.target?.select()
  }

  const updateTotalValue = (countryCode: string, phoneNumber: string) => {
    const value = '+' + countryCode + phoneNumber
    setCurrentValue(value)
    onChange(value)

    // set value manually, otherwise not recognized by react-hook-forms
    setValue(property, value, {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  const onPhoneNumberChange = (ev) => {
    const value = ev?.target?.value
    setCurrentPhoneNumberValue(value)
    updateTotalValue(currentCountryCodeValue, value)
  }

  const onCountryCodeChange = (ev) => {
    const value = ev?.target?.value
    setCurrentCountryCodeValue(value)
    updateTotalValue(value, currentPhoneNumberValue)
  }

  return (
    <div className="phone-number-input flex flex-col mb-4">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        readonly={readonly}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <div className="phone-number-input-inner relative flex space-x-2">
        <div className="phone-number-input-predial flex w-[150px] relative">
          <select
            className={`phone-number-input-predial-select flex w-full bg-form text-formContrast text-sm border-[.5px] border-formBorder rounded-md h-[42px] pl-2 pr-8 py-[10px] focus:outline-none focus:border-primary appearance-none cursor-pointer transition hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder`}
            onChange={onCountryCodeChange}
            value={currentCountryCodeValue}
            disabled={fixedCountryCallingCode != null}
          >
            {countryCodes.map((option) => (
              <option key={option.countryId} value={option.value}>
                {option.name} ({option.countryName})
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            icon={faChevronDown}
            className="absolute right-3 bottom-[13px] opacity-60 pointer-events-none"
          />
        </div>

        <input
          className={`phone-number-input-input flex w-[calc(100%-150px)] break-all bg-form text-formContrast text-sm border-[.5px] border-formBorder rounded-md h-[42px] px-2 py-[10px] focus:outline-none transition ${
            !disabled && !readonly
              ? 'focus:border-primary hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder'
              : ''
          } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${
            readonly || disabled ? 'bg-gray-500 bg-opacity-30' : ''
          }`}
          placeholder={placeholder}
          onClick={onPhoneNumberClick}
          onChange={onPhoneNumberChange}
          readOnly={readonly}
          disabled={disabled}
          value={currentPhoneNumberValue}
          type="tel"
          inputMode="tel"
        />

        <input
          type="hidden"
          id={property}
          name={property}
          value={currentValue}
          {...register(property, {
            required: required,
            validate: validators,
            minLength: minLength,
            maxLength: maxLength,
            pattern: pattern,
          })}
        />
      </div>

      {additionalActionText && (
        <div
          className="phone-number-input-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}
      <FormElementErrorMessage
        customMessage={customMessage}
        customMessageType={customMessageType}
        errors={errors}
        minLength={minLength}
        maxLength={maxLength}
      />
    </div>
  )
}

type PhoneNumberPrefixSelectOption = SelectOption & {
  countryId: number
  countryName: string
}
