import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { OddValueFormat } from '../../../hooks/useOddsFormat'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { getCurrencySymbol } from '../../../util/currency'
import {
  getIntlAmericanOddNumberFormat,
  getIntlDecimalOddNumberFormat,
  getIntlPotentialWinningOddNumberFormat,
} from '../../../util/internationalization'
import { HelpTextProps } from '../../HelpText'
import { FormElementLabel } from './Input'
import { useSportsBettingSettings } from '../../../lib/content'
import { OddFormatEnabledModeDTO } from '@arland-bmnext/webapps-api-data/cms/oddsFormatEnabledModes.dto'

export type OddsFormatSelectElementProps = {
  label?: string
  required?: boolean
  showOptionalIfNotRequired: boolean
  property?: string
  register?: UseFormRegister<any>
  value?: OddValueFormat
  setValue?: (...args: any) => void
  onChange?: (...args: any) => void
  additionalActionText?: string
  additionalActionFn?: (...args) => any
  helpText?: HelpTextProps
}

export const OddsFormatSelectElement = ({
  label = '',
  required = false,
  showOptionalIfNotRequired = true,
  property = undefined,
  setValue = (...args) => null,
  onChange = (...args) => null,
  value = OddValueFormat.PotentialWinnings,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
}: OddsFormatSelectElementProps) => {
  const { t } = useTranslation('common')
  const currency = useUserCurrency()
  const sportsBettingSettings = useSportsBettingSettings()

  const [currentValue, setCurrentValue] = useState<OddValueFormat>(value)

  useEffect(() => {
    _onChange(value)
  }, [])

  const _onChange = (ev) => {
    setCurrentValue(ev)

    if (onChange) {
      onChange(ev)
    }

    if (setValue) {
      setValue(property, ev, {
        shouldValidate: true,
        shouldTouch: true,
      })
    }
  }

  return (
    <div className="odds-format-select flex flex-col mb-4 relative w-full">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <div className="odds-format-select-options flex w-full gap-2 flex-wrap">
        {sportsBettingSettings?.oddsDisplay?.oddsFormat?.enabledModes?.includes(
          OddFormatEnabledModeDTO.PotentialWinnings,
        ) && (
          <OddsButtonGroup
            displayMode={OddValueFormat.PotentialWinnings}
            selected={currentValue === OddValueFormat.PotentialWinnings}
            setOddsFormat={_onChange}
            currency={currency}
            label={t('OddsFormatSelector.OddsFormat.return')}
            values={[18, 61, 71]}
          />
        )}

        {sportsBettingSettings?.oddsDisplay?.oddsFormat?.enabledModes?.includes(
          OddFormatEnabledModeDTO.DecimalOdds,
        ) && (
          <OddsButtonGroup
            displayMode={OddValueFormat.DecimalOdds}
            selected={currentValue === OddValueFormat.DecimalOdds}
            setOddsFormat={_onChange}
            currency={currency}
            label={t('OddsFormatSelector.OddsFormat.european')}
            values={[1.8, 6.1, 7.1]}
          />
        )}

        {sportsBettingSettings?.oddsDisplay?.oddsFormat?.enabledModes?.includes(OddFormatEnabledModeDTO.American) && (
          <OddsButtonGroup
            displayMode={OddValueFormat.AmericanOdds}
            selected={currentValue === OddValueFormat.AmericanOdds}
            setOddsFormat={_onChange}
            currency={currency}
            label={t('OddsFormatSelector.OddsFormat.american')}
            values={[-125, 510, 610]}
          />
        )}

        {sportsBettingSettings?.oddsDisplay?.oddsFormat?.enabledModes?.includes(OddFormatEnabledModeDTO.HongKong) && (
          <OddsButtonGroup
            displayMode={OddValueFormat.HongKongOdds}
            selected={currentValue === OddValueFormat.HongKongOdds}
            setOddsFormat={_onChange}
            currency={currency}
            label={t('OddsFormatSelector.OddsFormat.hongkong')}
            values={[0.8, 5.1, 6.1]}
          />
        )}
      </div>

      {additionalActionText && (
        <div
          className="odds-format-select-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}
    </div>
  )
}

const OddsButtonGroup = ({ selected, displayMode, setOddsFormat, currency, label, values }) => {
  return (
    <div
      className={`odds-button-group flex flex-col items-center space-y-2 p-1 pb-2 transition-all duration-300 ${
        selected ? 'odds-button-group-selected bg-gray-500 bg-opacity-10 rounded-md' : ''
      }`}
      onClick={() => setOddsFormat(displayMode)}
    >
      <div className="odds-button-group-odds-buttons flex space-x-1">
        <OddsButton displayMode={displayMode} selected={false} oddDisplayValue={values[0]} currency={currency} />
        <OddsButton displayMode={displayMode} selected={selected} oddDisplayValue={values[1]} currency={currency} />
        <OddsButton displayMode={displayMode} selected={false} oddDisplayValue={values[2]} currency={currency} />
      </div>
      <span className={`odds-button-group-label text-xs ${selected ? 'font-medium' : 'opacity-60'}`}>{label}</span>
    </div>
  )
}

const OddsButton = ({ selected, displayMode, oddDisplayValue, currency }) => {
  return (
    <div
      className={`odds-button flex flex-col w-odd h-odd relative rounded-[var(--odd-radius)] border-[1px] justify-between whitespace-nowrap overflow-hidden transition-all duration-300 ${
        selected
          ? 'odds-button-selected bg-oddSelected border-oddSelectedBorder text-oddSelectedContrast'
          : 'bg-form border-formBorder text-formContrast hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder'
      }`}
    >
      <div className="odds-button-inner flex flex-col justify-center space-y-1 items-center font-normal w-full h-full">
        <>
          <span className="odds-button-value text-xs font-medium leading-[10px]">
            {displayMode === OddValueFormat.Fractional ? (
              oddDisplayValue
            ) : (
              <>
                {oddDisplayValue != null && oddDisplayValue != '' && !isNaN(oddDisplayValue as number) && (
                  <>
                    {displayMode === OddValueFormat.PotentialWinnings &&
                      getIntlPotentialWinningOddNumberFormat(oddDisplayValue as number)}

                    {displayMode === OddValueFormat.DecimalOdds &&
                      getIntlDecimalOddNumberFormat(oddDisplayValue as number)}

                    {displayMode === OddValueFormat.AmericanOdds &&
                      getIntlAmericanOddNumberFormat(oddDisplayValue as number)}

                    {displayMode === OddValueFormat.HongKongOdds &&
                      getIntlDecimalOddNumberFormat(oddDisplayValue as number)}
                  </>
                )}
              </>
            )}
          </span>

          {displayMode === OddValueFormat.PotentialWinnings && (
            <span className="odds-button-currency text-xxs leading-3 opacity-60">
              {getCurrencySymbol(currency, 'shortSign')}
            </span>
          )}
        </>
      </div>
    </div>
  )
}
