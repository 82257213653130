import React, { useEffect, useState } from 'react'
import { FormElementErrorMessage, FormElementLabel } from './Input'
import { range } from '../../../util/date'
import useTranslation from 'next-translate/useTranslation'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { isNullOrEmpty } from '../../../util/string'

dayjs.extend(localeData)

type DatePart = 'Day' | 'Month' | 'Year'

export const DateSelect = ({
  label = '',
  required = false,
  showOptionalIfNotRequired = true,
  property = undefined,
  validators = {},
  errors = undefined,
  register = (...args) => null,
  setValue = (...args) => null,
  onChange = (...args) => null,
  getValues = (...args) => null,
  value = getValues(property),
  helpText = null,
  disabled = false,
}) => {
  const { t } = useTranslation('common')
  const [currentValue, setCurrentValue] = useState(undefined)

  useEffect(() => {
    if (!isNullOrEmpty(value)) {
      setCurrentValue(new Date(value))
    }
  }, [value])

  const updateDayIfNeeded = (newYear: number, newMonth: number) => {
    const daysInMonth = new Date(newYear, newMonth + 1, 0).getDate()
    if (currentValue.getDate() > daysInMonth) {
      currentValue.setDate(daysInMonth)
    }
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target
    if (isNaN(value as any)) return

    const parsedValue = parseInt(value)

    const nextValue = currentValue ?? new Date()

    switch (name) {
      case `${property}Month`:
        updateDayIfNeeded(nextValue.getFullYear(), parsedValue)
        nextValue.setMonth(parsedValue)
        break

      case `${property}Day`:
        nextValue.setDate(parsedValue)
        break

      case `${property}Year`:
        updateDayIfNeeded(parsedValue, nextValue.getMonth())
        nextValue.setFullYear(parsedValue)
        break

      default:
        return
    }

    onChange(nextValue)
    setValue(property, nextValue, { shouldValidate: true, shouldTouch: true })
    setCurrentValue(nextValue)
  }

  const renderSelectOptions = (datePart: DatePart) => {
    let options = []
    const _currentValue = currentValue ?? new Date()

    switch (datePart) {
      case 'Day':
        options = Array.from(range(1, new Date(_currentValue.getFullYear(), _currentValue.getMonth() + 1, 0).getDate()))
        break
      case 'Month':
        options = Array.from(range(1, 12))
        break
      case 'Year':
        options = Array.from(range(new Date().getFullYear() - 150, new Date().getFullYear() - 18)).reverse()
        break
      default:
        break
    }

    return options.map((option, idx) => (
      <option key={idx} value={datePart === 'Month' ? parseInt(option) - 1 : parseInt(option)}>
        {datePart === 'Month' ? t(`Calendar.${dayjs.months()[parseInt(option) - 1].toLowerCase()}`) : option}
      </option>
    ))
  }

  return (
    <div className="form-date-select flex flex-col mb-4 relative">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <div className="form-date-select-inner flex space-x-2">
        {['Day', 'Month', 'Year'].map((datePart: DatePart) => (
          <div key={datePart} className={`form-date-select-${datePart.toLowerCase()} flex w-full`}>
            <select
              name={`${property}${datePart}`}
              className={`form-date-select-select w-full overflow-hidden bg-form text-formContrast border-[.5px] border-formBorder rounded-md text-sm h-[42px] pl-4 pr-8 py-[10px] focus:outline-none focus:border-primary appearance-none cursor-pointer transition hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder ${
                errors ? 'border-red-500' : ''
              }`}
              onChange={(e) => handleDateChange(e)}
              value={
                datePart === 'Day'
                  ? currentValue?.getDate()
                  : datePart === 'Month'
                    ? currentValue?.getMonth()
                    : datePart === 'Year'
                      ? currentValue?.getFullYear()
                      : undefined
              }
              disabled={disabled}
            >
              <option value="" hidden disabled>
                {t(`Date.${datePart.toLowerCase()}`)}
              </option>

              {renderSelectOptions(datePart)}
            </select>
          </div>
        ))}
      </div>

      <input
        type="hidden"
        id={property}
        name={property}
        value={currentValue ?? ''}
        {...register(property, {
          required: required,
          validate: validators,
        })}
      />

      {currentValue?.getFullYear() !== new Date().getFullYear() && <FormElementErrorMessage errors={errors} />}
    </div>
  )
}
