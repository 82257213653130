type SectionTitleProps = {
  title: string
  subtitle: string
  additionalClassNames: string
}

const SectionTitle = ({ title, subtitle, additionalClassNames }: SectionTitleProps) => {
  return (
    <div className={`section-title flex mt-6 mb-2 ${additionalClassNames}`}>
      <h2 className="text-lg font-semibold">{title}</h2>
      <h4 className="text-sm font-semibold">{subtitle}</h4>
    </div>
  )
}

export default SectionTitle
